import {OrderByOptions, useFirestoreConnect, WhereOptions} from "react-redux-firebase";
import {IDProp, SelectorStateType} from "../types";
import md5 from "md5";
import {useSelector} from "react-redux";

const useCollectionGroup = <T>(collection: string, where?: WhereOptions | WhereOptions[], orderBy?: OrderByOptions | OrderByOptions[], limit = 15): undefined | Record<string, T & IDProp> => {

  const storeHash = (where ? `${collection}_${md5(JSON.stringify([where]))}_${md5(JSON.stringify([orderBy]))}_${limit}` : collection).replaceAll('/', '_')

  useFirestoreConnect({
    collectionGroup: collection,
    orderBy: orderBy,
    limit: limit,
    where: where,
    storeAs: storeHash,
  })
  return useSelector((state: SelectorStateType<T>) => {
    if (!state.firestore.ordered[storeHash]) {
      return undefined;
    }
    return (state.firestore.ordered[storeHash] || {}) as Record<string, T & IDProp>
  })
}

export default useCollectionGroup