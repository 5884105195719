import {
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { COMPONENTS } from "../../../constants/colors";
import useProfile from "../../../hooks/firestore/useProfile";
import moment from "moment";
import { DB } from "../../../firebase";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import GroupCard from "./components/GroupCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ICON_STYLE } from "../../../utils/constants";

const Timetracking = () => {
  const profile = useProfile();
  const [reports, setReports] = useState<Array<Record<string, any>> | null>(
    null
  );

  const [selectedReport, setSelectedReport] = useState<string | null>(null);
  const [selectedGroups, setSelectedGroups] = useState<Array<
    Record<string, any>
  > | null>(null);

  useEffect(() => {
    DB()
      .collection(`timetracking/weekly_tracking/stored_data`)
      .orderBy(`periodStart`, "desc")
      .limit(10)
      .get()
      .then((reports) => {
        const reportArray: Array<Record<string, any>> = [];
        reports.forEach((report) => {
          reportArray.push({ ...report.data(), id: report.id });
        });
        setReports(reportArray);
        setSelectedReport(reportArray[0]?.id ?? null);
      });
  }, []);

  useEffect(() => {
    if (selectedReport !== null) {
      DB()
        .collection(
          `timetracking/weekly_tracking/stored_data/${selectedReport}/groups`
        )
        .orderBy(`groupName`, "asc")
        .get()
        .then((groups) => {
          const groupArray: Array<Record<string, any>> = [];
          groups.forEach((group) => {
            groupArray.push({ ...group.data() });
          });
          setSelectedGroups(groupArray);
        });
    } else {
      setSelectedGroups(null);
    }
  }, [selectedReport]);

  const handleReportSelect = (event: SelectChangeEvent) => {
    setSelectedGroups(null);
    setSelectedReport(event.target.value as string);
  };

  const convertSecondsToHoursAndMinutes = (
    seconds: number
  ): { hours: number; minutes: number; remainingSeconds: number } => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    return { hours, minutes, remainingSeconds };
  };

  const getClosedTasks = (tasks: Array<Record<string, any>>): number => {
    return tasks.filter((task) => {
      return task.status === 5;
    }).length;
  };

  const getCurrentReport = (
    selectedReport: string | null
  ): Record<string, any> | undefined => {
    if (selectedReport === null || reports === null || reports.length === 0)
      return undefined;
    return reports.filter((report) => {
      return report.id === selectedReport;
    })[0];
  };

  // const getLongestTasks = (
  //   tasks: Array<Record<string, any>>
  // ): Record<string, any> => {
  //   const sortedTasks = tasks.sort((t1, t2) => {
  //     if (t1.amountInSeconds > t2.amountInSeconds) {
  //       return -1;
  //     } else if (t1.amountInSeconds < t2.amountInSeconds) {
  //       return 1;
  //     } else {
  //       return 0;
  //     }
  //   });
  //   return sortedTasks[0];
  // };

  const getTaskCountLastWeek = (tasks: Array<Record<string, any>>): number => {
    const sortedTasks = tasks.filter((task) => {
      return task.amount > 0;
    });
    return sortedTasks.length;
  };
  const getTaskCountLastMonth = (tasks: Array<Record<string, any>>): number => {
    const sortedTasks = tasks.filter((task) => {
      return task.amountLastMonth > 0;
    });
    return sortedTasks.length;
  };
  const getTaskCountCurrentMonth = (
    tasks: Array<Record<string, any>>
  ): number => {
    const sortedTasks = tasks.filter((task) => {
      return task.amountCurrentMonth > 0;
    });
    return sortedTasks.length;
  };

  return (
    <div
      style={{
        width: "100%",
        maxWidth: "100%",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignContent: "flex-start",
        alignItems: "flex-start",
        backgroundColor: COMPONENTS.DASHBOARD.BACKGROUND,
        padding: 40,
        paddingBottom: 70,
      }}
    >
      <div
        className="dashboardWrapper"
        style={
          profile?.lastname?.toLowerCase() === "stepanek"
            ? { width: "100%", minWidth: "100%" }
            : {}
        }
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignContent: "center",
            alignItems: "center",
            marginBottom: 10,
          }}
        >
          <Typography
            style={{
              color: "#FFF",
              fontSize: 32,
              fontFamily: "Roboto",
              fontWeight: `500`,
            }}
          >
            {`Wöchentlicher Bericht`}
          </Typography>
          <FormControl style={{ width: "30%" }}>
            <Select
              style={{ color: "#FFF", borderColor: "#FFF" }}
              id="report_select"
              disabled={reports === null || reports?.length === 0}
              value={selectedReport ?? ``}
              onChange={handleReportSelect}
            >
              {reports?.map((report) => {
                // return (
                //   <MenuItem value={report.id}>{`Zeitraum: ${moment(
                //     report?.periodStart?.toDate()
                //   ).format("DD.MM.YYYY")} - ${moment(
                //     report?.periodEnd?.toDate()
                //   ).format("DD.MM.YYYY")}`}</MenuItem>
                // );
                return (
                  <MenuItem value={report.id}>{`KW ${moment(
                    report?.periodEnd?.toDate()
                  ).isoWeek()} ${moment(report?.periodEnd?.toDate()).format(
                    `YYYY`
                  )}`}</MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignContent: "center",
            alignItems: "center",
            marginBottom: 20,
          }}
        >
          <Typography
            style={{
              color: "#FFF",
              fontSize: 18,
              fontFamily: "Roboto",
              fontWeight: `300`,
            }}
          >
            {`Berichtszeitraum: ${moment(
              getCurrentReport(selectedReport)?.periodStart?.toDate()
            ).format("DD.MM.YYYY")} - ${moment(
              getCurrentReport(selectedReport)?.periodEnd?.toDate()
            ).format("DD.MM.YYYY")}`}
          </Typography>
        </div>
        <Grid container spacing={2}>
          {selectedGroups !== null &&
            selectedGroups.map((group) => {
              const timeLastWeek = convertSecondsToHoursAndMinutes(
                group.totalTimeInSeconds
              );
              const timeCurrentMonth = convertSecondsToHoursAndMinutes(
                group.totalTimeInSecondsCurrentMonth
              );
              const timeLastMonth = convertSecondsToHoursAndMinutes(
                group.totalTimeInSecondsLastMonth
              );
              return (
                <Grid item xs={12} md={12} lg={3}>
                  <GroupCard
                    title={`${group.groupName}`}
                    subtitle={``}
                    groupId={`${group.groupID}`}
                    link={`/tools/timetracking/${selectedReport}/${group.groupID}`}
                    // groupId={`${time.hours} Std ${time.minutes} Min`}
                  >
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flex: 1,
                        flexDirection: "column",
                        justifyContent: "center",
                        alignContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {group.groupImage !== null ? (
                        <img
                          id={`img_${group.id}`}
                          src={`${group.groupImage}`}
                          alt={`${group.groupName}`}
                          style={{ width: 80, height: 80, borderRadius: 50 }}
                        />
                      ) : (
                        <div
                          style={{
                            width: 80,
                            height: 80,
                            minHeight: 80,
                            backgroundColor: `#111`,
                            borderRadius: 50,
                            display: "flex",
                            flex: 1,
                            flexDirection: "column",
                            justifyContent: "center",
                            alignContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <FontAwesomeIcon
                            color={`#AAA`}
                            size={"3x"}
                            icon={[ICON_STYLE, "people-group"]}
                          />
                        </div>
                      )}
                      <Grid container spacing={1} style={{ marginTop: 10 }}>
                        <Grid item xs={8}>
                          <Typography
                            style={{
                              color: "#FFF",
                              fontSize: 14,
                              fontFamily: "Roboto",
                              fontWeight: `300`,
                            }}
                          >
                            {`Letzte Woche`}
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Chip
                            size="small"
                            label={
                              <Typography
                                style={{
                                  color: "#FFF",
                                  fontSize: 10,
                                  fontFamily: "Roboto",
                                  fontWeight: `300`,
                                }}
                              >
                                {`${timeLastWeek.hours} Std ${timeLastWeek.minutes} Min`}
                              </Typography>
                            }
                          />
                        </Grid>
                        <Grid item xs={8}>
                          <Typography
                            style={{
                              color: "#FFF",
                              fontSize: 14,
                              fontFamily: "Roboto",
                              fontWeight: `300`,
                            }}
                          >
                            {`Diesen Monat`}
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Chip
                            size="small"
                            label={
                              <Typography
                                style={{
                                  color: "#FFF",
                                  fontSize: 10,
                                  fontFamily: "Roboto",
                                  fontWeight: `300`,
                                }}
                              >
                                {`${timeCurrentMonth.hours} Std ${timeCurrentMonth.minutes} Min`}
                              </Typography>
                            }
                          />
                        </Grid>
                        <Grid item xs={8}>
                          <Typography
                            style={{
                              color: "#FFF",
                              fontSize: 14,
                              fontFamily: "Roboto",
                              fontWeight: `300`,
                            }}
                          >
                            {`Letzten Monat`}
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Chip
                            size="small"
                            label={
                              <Typography
                                style={{
                                  color: "#FFF",
                                  fontSize: 10,
                                  fontFamily: "Roboto",
                                  fontWeight: `300`,
                                }}
                              >
                                {`${timeLastMonth.hours} Std ${timeLastMonth.minutes} Min`}
                              </Typography>
                            }
                          />
                        </Grid>
                        {/* <Grid item xs={8}>
                          <Typography
                            style={{
                              color: "#FFF",
                              fontSize: 14,
                              fontFamily: "Roboto",
                              fontWeight: `300`,
                            }}
                          >
                            {`Bearbeitete Aufgaben`}
                          </Typography>
                        </Grid>
                        <Grid item xs={1}>
                          <Typography
                            style={{
                              color: "#FFF",
                              fontSize: 16,
                              fontFamily: "Roboto",
                              fontWeight: `700`,
                            }}
                          >
                            {`${
                              getTaskCountLastWeek(group?.positions ?? []) ?? 0
                            }`}
                          </Typography>
                        </Grid>
                        <Grid item xs={1}>
                          <Typography
                            style={{
                              color: "#FFF",
                              fontSize: 16,
                              fontFamily: "Roboto",
                              fontWeight: `700`,
                            }}
                          >
                            {`${
                              getTaskCountCurrentMonth(
                                group?.positions ?? []
                              ) ?? 0
                            }`}
                          </Typography>
                        </Grid>
                        <Grid item xs={1}>
                          <Typography
                            style={{
                              color: "#FFF",
                              fontSize: 16,
                              fontFamily: "Roboto",
                              fontWeight: `700`,
                            }}
                          >
                            {`${
                              getTaskCountLastMonth(group?.positions ?? []) ?? 0
                            }`}
                          </Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <Typography
                            style={{
                              color: "#FFF",
                              fontSize: 14,
                              fontFamily: "Roboto",
                              fontWeight: `300`,
                            }}
                          >
                            {`Davon abgeschlossen`}
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography
                            style={{
                              color: "#FFF",
                              fontSize: 16,
                              fontFamily: "Roboto",
                              fontWeight: `700`,
                            }}
                          >
                            {`${getClosedTasks(group?.positions ?? [])}`}
                          </Typography>
                        </Grid> */}
                        {/* <Grid item xs={12}>
                          <Typography
                            style={{
                              color: "#FFF",
                              fontSize: 14,
                              fontFamily: "Roboto",
                              fontWeight: `300`,
                            }}
                          >
                            {`Größter Zeitaufwand`}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            style={{
                              color: "#FFF",
                              fontSize: 16,
                              fontFamily: "Roboto",
                              fontWeight: `700`,
                            }}
                          >
                            {`${
                              getLongestTasks(group?.positions ?? [])?.name ??
                              `n/a`
                            }`}
                          </Typography>
                        </Grid> */}
                      </Grid>
                    </div>
                  </GroupCard>
                </Grid>
              );
            })}
        </Grid>
      </div>
    </div>
  );
};

export default Timetracking;
