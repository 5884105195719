import React from "react";
import { Route, Routes } from "react-router-dom";
// import Bitrix2GitlabView from "./bitrx2gitlab";
import Bitrix2SevdeskView from "./bitrix2sevdesk";
import Firstpromoter from "./firstpromoter";
import Timetracking from "./timetracking";
import TimetrackingDetail from "./timetracking/components/Detailpage";

const ToolsModule = () => {
  return (
    <Routes>
      {/* <Route path={`bitrix2gitlab`} element={<Bitrix2GitlabView />} /> */}
      <Route path={`bitrix2sevdesk`} element={<Bitrix2SevdeskView />} />
      <Route path={`firstpromoter`} element={<Firstpromoter />} />
      <Route
        path={`timetracking/:report/:group`}
        element={<TimetrackingDetail />}
      />
      <Route path={`timetracking`} element={<Timetracking />} />
    </Routes>
  );
};

export default ToolsModule;
