import {
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import moment from "moment";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useProfile from "../../../../../hooks/firestore/useProfile";
import { COMPONENTS } from "../../../../../constants/colors";
import { useParams } from "react-router-dom";
import { DB } from "../../../../../firebase";
import DetailCard from "./components/DetailCard";
import { Doughnut } from "react-chartjs-2";
import { Chart, ArcElement } from "chart.js";
import { ICON_STYLE } from "../../../../../utils/constants";

const TimetrackingDetail = () => {
  const profile = useProfile();
  const { report, group } = useParams<{ report: string; group: string }>();
  const [reportData, setReportData] = useState<Record<string, any> | null>(
    null
  );
  const [reportMetadata, setReportMetadata] = useState<Record<
    string,
    any
  > | null>(null);

  Chart.register(ArcElement);

  useEffect(() => {
    DB()
      .collection(`timetracking/weekly_tracking/stored_data/${report}/groups`)
      .doc(group)
      .get()
      .then((group) => {
        if (group.exists) {
          const data = group.data();
          setReportData(data ?? null);
        }
      });
  }, [report, group]);

  useEffect(() => {
    DB()
      .collection(`timetracking/weekly_tracking/stored_data`)
      .doc(report)
      .get()
      .then((group) => {
        if (group.exists) {
          const data = group.data();
          setReportMetadata(data ?? null);
        }
      });
  }, [report, group]);

  const convertSecondsToHoursAndMinutes = (
    seconds: number
  ): { hours: number; minutes: number; remainingSeconds: number } => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    return { hours, minutes, remainingSeconds };
  };

  const getClosedTasks = (tasks: Array<Record<string, any>>): number => {
    return tasks.filter((task) => {
      return task.status === 5;
    }).length;
  };

  const getTaskListLastWeek = (
    tasks: Array<Record<string, any>>
  ): Array<Record<string, any>> => {
    return tasks
      .filter((task) => {
        return task.amount > 0;
      })
      .sort((t1, t2) => {
        if (t1.amount > t2.amount) {
          return -1;
        } else if (t1.amount < t2.amount) {
          return 1;
        } else {
          return 0;
        }
      });
  };

  const getTaskListCurrentMonth = (
    tasks: Array<Record<string, any>>
  ): Array<Record<string, any>> => {
    return tasks
      .filter((task) => {
        return task.amountCurrentMonth > 0;
      })
      .sort((t1, t2) => {
        if (t1.amountCurrentMonth > t2.amountCurrentMonth) {
          return -1;
        } else if (t1.amountCurrentMonth < t2.amountCurrentMonth) {
          return 1;
        } else {
          return 0;
        }
      });
  };

  const getTaskListLastMonth = (
    tasks: Array<Record<string, any>>
  ): Array<Record<string, any>> => {
    return tasks
      .filter((task) => {
        return task.amountLastMonth > 0;
      })
      .sort((t1, t2) => {
        if (t1.amountLastMonth > t2.amountLastMonth) {
          return -1;
        } else if (t1.amountLastMonth < t2.amountLastMonth) {
          return 1;
        } else {
          return 0;
        }
      });
  };

  // const getLongestTasks = (
  //   tasks: Array<Record<string, any>>
  // ): Record<string, any> => {
  //   const sortedTasks = tasks.sort((t1, t2) => {
  //     if (t1.amountInSeconds > t2.amountInSeconds) {
  //       return -1;
  //     } else if (t1.amountInSeconds < t2.amountInSeconds) {
  //       return 1;
  //     } else {
  //       return 0;
  //     }
  //   });
  //   return sortedTasks[0];
  // };

  const getTaskCountLastWeek = (tasks: Array<Record<string, any>>): number => {
    const sortedTasks = tasks.filter((task) => {
      return task.amount > 0;
    });
    return sortedTasks.length;
  };
  const getTaskCountLastMonth = (tasks: Array<Record<string, any>>): number => {
    const sortedTasks = tasks.filter((task) => {
      return task.amountLastMonth > 0;
    });
    return sortedTasks.length;
  };
  const getTaskCountCurrentMonth = (
    tasks: Array<Record<string, any>>
  ): number => {
    const sortedTasks = tasks.filter((task) => {
      return task.amountCurrentMonth > 0;
    });
    return sortedTasks.length;
  };

  const data = {
    labels: ["Letzte Woche", "Dieser Monat", "Letzter Monat"],
    datasets: [
      {
        label: "30_days",
        data: [
          getTaskCountLastWeek(reportData?.positions ?? []),
          getTaskCountCurrentMonth(reportData?.positions ?? []),
          getTaskCountLastMonth(reportData?.positions ?? []),
        ],
        backgroundColor: [
          COMPONENTS.DASHBOARD.CHART.COLOR_2,
          COMPONENTS.DASHBOARD.CHART.COLOR_3,
          COMPONENTS.DASHBOARD.CHART.COLOR_4,
        ],
        borderWidth: 0,
      },
    ],
  };

  if (reportData === null) return null;

  return (
    <div
      style={{
        width: "100%",
        maxWidth: "100%",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignContent: "flex-start",
        alignItems: "flex-start",
        backgroundColor: COMPONENTS.DASHBOARD.BACKGROUND,
        padding: 40,
        paddingBottom: 70,
      }}
    >
      <div
        className="dashboardWrapper"
        style={
          profile?.lastname?.toLowerCase() === "stepanek"
            ? { width: "100%", minWidth: "100%" }
            : {}
        }
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignContent: "center",
            alignItems: "center",
            marginBottom: 10,
          }}
        >
          <Typography
            style={{
              color: "#FFF",
              fontSize: 32,
              fontFamily: "Roboto",
              fontWeight: `500`,
            }}
          >
            {`Wöchentlicher Bericht`}
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignContent: "center",
            alignItems: "center",
            marginBottom: 20,
          }}
        >
          {reportMetadata !== null && (
            <Typography
              style={{
                color: "#FFF",
                fontSize: 18,
                fontFamily: "Roboto",
                fontWeight: `300`,
              }}
            >
              {`Berichtszeitraum: ${moment(
                reportMetadata?.periodStart?.toDate()
              ).format("DD.MM.YYYY")} - ${moment(
                reportMetadata?.periodEnd?.toDate()
              ).format("DD.MM.YYYY")}`}
            </Typography>
          )}
        </div>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={6}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={12}>
                <DetailCard
                  title={`Allgemeine Informationen`}
                  subtitle=""
                  time={`${reportData?.groupID ?? `-`}`}
                >
                  <Grid container spacing={4} alignItems={"center"}>
                    <Grid item xs={4}>
                      {reportData?.groupImage !== null ? (
                        <img
                          id={`img_${reportData?.groupID}`}
                          src={`${reportData?.groupImage}`}
                          alt={`${reportData?.groupName}`}
                          style={{ width: 80, height: 80, borderRadius: 50 }}
                        />
                      ) : (
                        <div
                          style={{
                            width: 80,
                            height: 80,
                            minHeight: 80,
                            backgroundColor: `#111`,
                            borderRadius: 50,
                            display: "flex",
                            flex: 1,
                            flexDirection: "column",
                            justifyContent: "center",
                            alignContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <FontAwesomeIcon
                            color={`#AAA`}
                            size={"3x"}
                            icon={[ICON_STYLE, "people-group"]}
                          />
                        </div>
                      )}
                    </Grid>
                    <Grid item xs={8}>
                      <Grid container spacing={1} alignItems={"center"}>
                        <Grid item xs={3}>
                          <Typography
                            style={{
                              color: "#FFF",
                              fontSize: 16,
                              fontFamily: "Roboto",
                              fontWeight: `500`,
                            }}
                          >{`Name:`}</Typography>
                        </Grid>
                        <Grid item xs={9}>
                          <Typography
                            style={{
                              color: "#FFF",
                              fontSize: 16,
                              fontFamily: "Roboto",
                              fontWeight: `700`,
                            }}
                          >{`${reportData?.groupName}`}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography
                            style={{
                              color: "#FFF",
                              fontSize: 16,
                              fontFamily: "Roboto",
                              fontWeight: `500`,
                            }}
                          >{`Nummer:`}</Typography>
                        </Grid>
                        <Grid item xs={9}>
                          <Typography
                            style={{
                              color: "#FFF",
                              fontSize: 16,
                              fontFamily: "Roboto",
                              fontWeight: `700`,
                            }}
                          >{`${reportData?.groupID}`}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography
                            style={{
                              color: "#FFF",
                              fontSize: 16,
                              fontFamily: "Roboto",
                              fontWeight: `500`,
                            }}
                          >{`Aufgaben:`}</Typography>
                        </Grid>
                        <Grid item xs={9}>
                          <Typography
                            style={{
                              color: "#FFF",
                              fontSize: 16,
                              fontFamily: "Roboto",
                              fontWeight: `700`,
                            }}
                          >{`${
                            reportData?.positions?.length ?? 0
                          }`}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </DetailCard>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <DetailCard title={`Aktive Aufgaben`} subtitle="" time="gesamt">
                  <div
                    style={{
                      width: "100%",
                      minWidth: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Grid container spacing={4} alignItems={"center"}>
                      <Grid item xs={4}>
                        <Doughnut
                          data={data}
                          options={{
                            responsive: true,
                            plugins: {
                              legend: {
                                position: "top",
                                display: false,
                              },
                              title: {
                                display: false,
                                text: "",
                              },
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={8}>
                        <Grid container spacing={1} alignItems={"center"}>
                          <Grid item xs={2}>
                            <div
                              style={{
                                backgroundColor:
                                  COMPONENTS.DASHBOARD.CHART.COLOR_2,
                                width: 20,
                                height: 20,
                                borderRadius: 20,
                              }}
                            />
                          </Grid>
                          <Grid item xs={6}>{`Letzte Woche`}</Grid>
                          <Grid item xs={4}>{`${getTaskCountLastWeek(
                            reportData?.positions ?? []
                          )}`}</Grid>
                          <Grid item xs={2}>
                            <div
                              style={{
                                backgroundColor:
                                  COMPONENTS.DASHBOARD.CHART.COLOR_3,
                                width: 20,
                                height: 20,
                                borderRadius: 20,
                              }}
                            />
                          </Grid>
                          <Grid item xs={6}>{`Dieser Monat`}</Grid>
                          <Grid item xs={4}>{`${getTaskCountCurrentMonth(
                            reportData?.positions ?? []
                          )}`}</Grid>
                          <Grid item xs={2}>
                            <div
                              style={{
                                backgroundColor:
                                  COMPONENTS.DASHBOARD.CHART.COLOR_4,
                                width: 20,
                                height: 20,
                                borderRadius: 20,
                              }}
                            />
                          </Grid>
                          <Grid item xs={6}>{`Letzter Monat`}</Grid>
                          <Grid item xs={4}>{`${getTaskCountLastMonth(
                            reportData?.positions ?? []
                          )}`}</Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                </DetailCard>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <DetailCard
                  title={`Zeitaufwand`}
                  subtitle="gesamt"
                  time="Letzte Woche"
                >
                  <div
                    style={{
                      width: "100%",
                      minWidth: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      style={{
                        color: "#FFF",
                        fontSize: 28,
                        fontFamily: "Roboto",
                        fontWeight: `500`,
                      }}
                    >
                      {`${
                        convertSecondsToHoursAndMinutes(
                          reportData?.totalTimeInSeconds ?? 0
                        ).hours
                      } Std ${
                        convertSecondsToHoursAndMinutes(
                          reportData?.totalTimeInSeconds ?? 0
                        ).minutes
                      } Min`}
                    </Typography>
                  </div>
                </DetailCard>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <DetailCard
                  title={`Zeitaufwand`}
                  subtitle="gesamt"
                  time="Diesen Monat"
                >
                  <div
                    style={{
                      width: "100%",
                      minWidth: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      style={{
                        color: "#FFF",
                        fontSize: 28,
                        fontFamily: "Roboto",
                        fontWeight: `500`,
                      }}
                    >
                      {`${
                        convertSecondsToHoursAndMinutes(
                          reportData?.totalTimeInSecondsCurrentMonth ?? 0
                        ).hours
                      } Std ${
                        convertSecondsToHoursAndMinutes(
                          reportData?.totalTimeInSecondsCurrentMonth ?? 0
                        ).minutes
                      } Min`}
                    </Typography>
                  </div>
                </DetailCard>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <DetailCard
                  title={`Zeitaufwand`}
                  subtitle="gesamt"
                  time="Letzter Monat"
                >
                  <div
                    style={{
                      width: "100%",
                      minWidth: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      style={{
                        color: "#FFF",
                        fontSize: 28,
                        fontFamily: "Roboto",
                        fontWeight: `500`,
                      }}
                    >
                      {`${
                        convertSecondsToHoursAndMinutes(
                          reportData?.totalTimeInSecondsLastMonth ?? 0
                        ).hours
                      } Std ${
                        convertSecondsToHoursAndMinutes(
                          reportData?.totalTimeInSecondsLastMonth ?? 0
                        ).minutes
                      } Min`}
                    </Typography>
                  </div>
                </DetailCard>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12} lg={6}>
            <DetailCard
              title={`Aufgaben`}
              subtitle="Aufgaben nach Zeit"
              time="Letzte Woche"
            >
              {getTaskListLastWeek(reportData?.positions ?? []).map((task) => {
                return (
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={12} lg={8}>
                      <Typography
                        style={{
                          color: "#FFF",
                          fontSize: 12,
                          fontFamily: "Roboto",
                          fontWeight: `700`,
                        }}
                      >
                        {task.name}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={12} lg={2}>
                      <Typography
                        style={{
                          color: "#FFF",
                          fontSize: 12,
                          fontFamily: "Roboto",
                          fontWeight: `300`,
                        }}
                      >
                        {`${
                          convertSecondsToHoursAndMinutes(task?.amount ?? 0)
                            .hours
                        } Std ${
                          convertSecondsToHoursAndMinutes(task?.amount ?? 0)
                            .minutes
                        } Min`}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={12} lg={2}>
                      <Chip
                        size="small"
                        label={
                          <Typography
                            style={{
                              color: task?.status === 5 ? "#400505" : "#FFF",
                              fontSize: 10,
                              fontFamily: "Roboto",
                              fontWeight: `300`,
                            }}
                          >
                            {`${task?.status === 5 ? `Geschlossen` : `Aktiv`}`}
                          </Typography>
                        }
                      />
                    </Grid>
                  </Grid>
                );
              })}
            </DetailCard>
          </Grid>
          <Grid item xs={12} md={12} lg={6}>
            <DetailCard
              title={`Aufgaben`}
              subtitle="Aufgaben nach Zeit"
              time="Dieser Monat"
            >
              {getTaskListCurrentMonth(reportData?.positions ?? []).map(
                (task) => {
                  return (
                    <Grid container spacing={1}>
                      <Grid item xs={12} md={12} lg={8}>
                        <Typography
                          style={{
                            color: "#FFF",
                            fontSize: 12,
                            fontFamily: "Roboto",
                            fontWeight: `700`,
                          }}
                        >
                          {task.name}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={12} lg={2}>
                        <Typography
                          style={{
                            color: "#FFF",
                            fontSize: 12,
                            fontFamily: "Roboto",
                            fontWeight: `300`,
                          }}
                        >
                          {`${
                            convertSecondsToHoursAndMinutes(
                              task?.amountCurrentMonth ?? 0
                            ).hours
                          } Std ${
                            convertSecondsToHoursAndMinutes(
                              task?.amountCurrentMonth ?? 0
                            ).minutes
                          } Min`}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={12} lg={2}>
                        <Chip
                          size="small"
                          label={
                            <Typography
                              style={{
                                color: task?.status === 5 ? "#400505" : "#FFF",
                                fontSize: 10,
                                fontFamily: "Roboto",
                                fontWeight: `300`,
                              }}
                            >
                              {`${
                                task?.status === 5 ? `Geschlossen` : `Aktiv`
                              }`}
                            </Typography>
                          }
                        />
                      </Grid>
                    </Grid>
                  );
                }
              )}
            </DetailCard>
          </Grid>
          <Grid item xs={12} md={12} lg={6}>
            <DetailCard
              title={`Aufgaben`}
              subtitle="Aufgaben nach Zeit"
              time="Letzter Monat"
            >
              {getTaskListLastMonth(reportData?.positions ?? []).map((task) => {
                return (
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={12} lg={8}>
                      <Typography
                        style={{
                          color: "#FFF",
                          fontSize: 12,
                          fontFamily: "Roboto",
                          fontWeight: `700`,
                        }}
                      >
                        {task.name}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={12} lg={2}>
                      <Typography
                        style={{
                          color: "#FFF",
                          fontSize: 12,
                          fontFamily: "Roboto",
                          fontWeight: `300`,
                        }}
                      >
                        {`${
                          convertSecondsToHoursAndMinutes(
                            task?.amountLastMonth ?? 0
                          ).hours
                        } Std ${
                          convertSecondsToHoursAndMinutes(
                            task?.amountLastMonth ?? 0
                          ).minutes
                        } Min`}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={12} lg={2}>
                      <Chip
                        size="small"
                        label={
                          <Typography
                            style={{
                              color: task?.status === 5 ? "#400505" : "#FFF",
                              fontSize: 10,
                              fontFamily: "Roboto",
                              fontWeight: `300`,
                            }}
                          >
                            {`${task?.status === 5 ? `Geschlossen` : `Aktiv`}`}
                          </Typography>
                        }
                      />
                    </Grid>
                  </Grid>
                );
              })}
            </DetailCard>
          </Grid>
          <Grid item xs={12} md={12} lg={4}>
            <></>
          </Grid>
          <Grid item xs={12} md={12} lg={8}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={6}>
                <></>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <></>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <></>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12} lg={7}>
            <></>
          </Grid>
          <Grid item xs={12} md={12} lg={5}>
            <></>
          </Grid>
          <Grid item xs={12} md={12} lg={4}>
            <></>
          </Grid>
          <Grid item xs={12} md={12} lg={5}>
            <></>
          </Grid>
          <Grid item xs={12} md={12} lg={3}>
            <></>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default TimetrackingDetail;
