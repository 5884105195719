import {
  Card,
  CardContent,
  CardHeader,
  Chip,
  Typography,
} from "@material-ui/core";

import React from "react";
import { useNavigate } from "react-router-dom";
import { COMPONENTS } from "../../../../../../../constants/colors";

type DetailCardProps = {
  title: string;
  subtitle: string;
  children: React.ReactNode;
  time: string;
};

const DetailCard = ({ title, subtitle, time, children }: DetailCardProps) => {
  const navigate = useNavigate();
  return (
    <Card
      style={{
        height: "100%",
        backgroundColor: COMPONENTS.DASHBOARD.CARD.BACKGROUND,
        borderRadius: 5,
        borderStyle: "solid",
        borderWidth: 2,
        borderColor: `#AAA`,
      }}
    >
      <CardHeader
        title={
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              style={{
                color: "#FFF",
                fontSize: 16,
                fontFamily: "Roboto",
                fontWeight: `500`,
              }}
            >
              {`${title}`}
            </Typography>
            <Chip
              size="small"
              label={
                <Typography
                  style={{
                    color: "#FFF",
                    fontSize: 10,
                    fontFamily: "Roboto",
                    fontWeight: `300`,
                  }}
                >
                  {`${time}`}
                </Typography>
              }
            />
          </div>
        }
        subheader={
          <Typography
            style={{
              color: `#AAA`,
              fontSize: 14,
              fontFamily: "Roboto",
              fontWeight: `300`,
            }}
          >
            {`${subtitle}`}
          </Typography>
        }
      />
      <CardContent>{children}</CardContent>
    </Card>
  );
};

export default DetailCard;
