import React from "react";
import getFirebase from "./firebase";
import { createFirestoreInstance } from "redux-firestore";
import store from "./store";
import moment from "moment";
import { Provider } from "react-redux";
import {
  ReactReduxFirebaseProvider,
  ReduxFirestoreProvider,
} from "react-redux-firebase";
import { BrowserRouter } from "react-router-dom";
import MainLayout from "./layout/Main";
import ErrorBoundary from "./components/ErrorBoundary";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { CssBaseline } from "@material-ui/core";
import { deDE } from "@material-ui/core/locale";
import { SnackbarProvider } from "notistack";
//import * as Sentry from '@sentry/browser';
import { PaletteOptions } from "@material-ui/core/styles/createPalette";
// import { Context } from "@dash.bar/db-admin-types";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faArrowSquareRight } from "@fortawesome/pro-light-svg-icons";
import {
  faApple,
  faGitlab,
  faGoogle,
} from "@fortawesome/free-brands-svg-icons";
import {
  faMicrochip,
  faDesktop,
  faClock,
  faWindow,
  faCookie,
  faLanguage,
  faCheckCircle,
  faTimesCircle,
  faComment,
  faExpandWide,
  faUser,
  faUserSecret,
  faArrowCircleLeft,
  faArrowCircleRight,
  faBadgeCheck,
  faBan,
  faBook,
  faBoxCheck,
  faCheck,
  faCheckDouble,
  faCheckSquare,
  faCocktail,
  faCog,
  faCogs,
  faCopy,
  faCrown,
  faEdit,
  faExclamationTriangle,
  faExternalLink,
  faEye,
  faEyeSlash,
  faFile,
  faGrinStars,
  faHandHoldingUsd,
  faInboxIn,
  faInboxOut,
  faIndustry,
  faInfoCircle,
  faKey,
  faLink,
  faLock,
  faNewspaper,
  faPlug,
  faPlus,
  faPlusCircle,
  faQuestionCircle,
  faSave,
  faServer,
  faShieldAlt,
  faShoppingBasket,
  faShoppingCart,
  faSignOut,
  faSort,
  faSortAlphaDown,
  faSortAlphaUp,
  faSortAmountDown,
  faSortAmountUp,
  faSpinnerScale,
  faStore,
  faSync,
  faTachometer,
  faThunderstorm,
  faTimes,
  faTools,
  faTrash,
  faTrashAlt,
  faUserCrown,
  faUsers,
  faUserGraduate,
  faUserTie,
  faUserShield,
  faPuzzlePiece,
  faList,
  faMailBulk,
  faExclamationCircle,
  faExclamationSquare,
  faMoneyCheck,
  faCommentAltLines,
  faChartLine,
  faRedo,
  faArrowUp,
  faArrowDown,
  faArrowRight,
  faTerminal,
  faDownload,
  faClipboardList,
  faClipboardQuestion,
  faUtensils,
  faBurger,
  faMoneyBill,
  faCalendar,
  faCloud,
  faPuzzlePieceSimple,
  faFileCertificate,
  faPenToSquare,
  faSeal,
  faSealExclamation,
  faSealQuestion,
  faRotate,
  faRotateExclamation,
  faBell,
  faLockOpen,
  faKeyboard,
  faPalette,
  faGlobe,
  faFileChartColumn,
  faPeopleGroup,
} from "@fortawesome/pro-solid-svg-icons";
library.add(
  // @ts-ignore
  faCheckCircle,
  faExclamationCircle,
  faExclamationSquare,
  faTimesCircle,
  faTrashAlt,
  faPlusCircle,
  faEdit,
  faArrowSquareRight,
  faQuestionCircle,
  faTrash,
  faCrown,
  faShieldAlt,
  faUserCrown,
  faUserGraduate,
  faUserTie,
  faTimes,
  faCheckSquare,
  faTachometer,
  faCog,
  faCogs,
  faFile,
  faCheck,
  faPlug,
  faKeyboard,
  faPalette,
  faThunderstorm,
  faBoxCheck,
  faSave,
  faBan,
  faSync,
  faExclamationTriangle,
  faBadgeCheck,
  faGrinStars,
  faEye,
  faEyeSlash,
  faArrowCircleRight,
  faSortAlphaDown,
  faSortAlphaUp,
  faSort,
  faArrowCircleLeft,
  faCheckDouble,
  faGitlab,
  faExternalLink,
  faShoppingCart,
  faBook,
  faInboxIn,
  faInboxOut,
  faPlus,
  faCopy,
  faHandHoldingUsd,
  faLock,
  faSignOut,
  faServer,
  faLink,
  faInfoCircle,
  faCocktail,
  faUsers,
  faTools,
  faNewspaper,
  faIndustry,
  faShoppingBasket,
  faStore,
  faSortAmountUp,
  faSortAmountDown,
  faGoogle,
  faKey,
  faApple,
  faUserShield,
  faList,
  faPuzzlePiece,
  faMailBulk,
  faMoneyCheck,
  faCommentAltLines,
  faChartLine,
  faRedo,
  faArrowUp,
  faArrowDown,
  faArrowRight,
  faTerminal,
  faUser,
  faDownload,
  faClipboardList,
  faClipboardQuestion,
  faMicrochip,
  faDesktop,
  faClock,
  faWindow,
  faCookie,
  faLanguage,
  faComment,
  faExpandWide,
  faUserSecret,
  faUtensils,
  faBurger,
  faMoneyBill,
  faCalendar,
  faCloud,
  faPuzzlePieceSimple,
  faFileCertificate,
  faPenToSquare,
  faSeal,
  faSealExclamation,
  faSealQuestion,
  faRotate,
  faRotateExclamation,
  faBell,
  faLockOpen,
  faSpinnerScale,
  faGlobe,
  faFileChartColumn,
  faPeopleGroup
);

/*
Sentry.init({
    dsn: "https://b4b5eed2d6904f30a83596991e4de719@sentry.dash.bar/10",
    debug: process.env.NODE_ENV !== 'production',
    environment: process.env.NODE_ENV,
    beforeSend(event, hint) {
        // Check if it is an exception, and if so, show the report dialog
        if (process.env.NODE_ENV === 'production' && event.exception) {
            Sentry.showReportDialog({eventId: event.event_id});
        }
        return event;
    },
});
 */

const rrfConfig = {
  userProfile: "user",
  //presence: 'presence',
  //sessions: 'sessions',
  useFirestoreForProfile: true,
  useFirestoreForStorageMeta: true,
  enableLogging: process.env.NODE_ENV !== "production",
  attachAuthIsReady: true,
  logErrors: process.env.NODE_ENV !== "production",
  //autoPopulateProfile: true,
};

const firebase = getFirebase();

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance,
};

moment.locale("de_DE");

const palette = (dark: boolean): PaletteOptions => {
  if (dark) {
    return {
      type: "dark",
      primary: {
        // main: "#0c151c",
        main: "#2985d0",
        // main: "#1a2c3b",
        contrastText: "#fff",
      },

      // secondary: {
      //   // main: '#73167d',
      //   main: "#2985d0",
      //   contrastText: "#fff",
      // },
    };
  }
  return {
    type: "light",
    primary: {
      main: "#2985d0",
      contrastText: "#333",
    },
    secondary: {
      main: "#73167d",
      contrastText: "#333",
    },
  };
};

function App() {
  const prefersDarkMode = true; // useMediaQuery('(prefers-color-scheme: dark)');
  const theme = React.useMemo(
    () =>
      createTheme(
        {
          palette: palette(prefersDarkMode),
          // overrides: {
          //   MuiLink: {
          //     root: {
          //       color: "#2985d0",
          //     },
          //   },
          //   MuiButton: {
          //     label: {
          //       color: "#FFF",
          //     },
          //   },
          //   MuiTab: {
          //     selected: {
          //       color: "#2985d0",
          //     },

          //   },
          // },
          typography: {
            fontFamily: [
              "Roboto",
              // "Montserrat",
              // "M PLUS 1",
              // "Jura",
              // "Quicksand",
              "Roboto",
            ].join(","),
          },
        },
        deDE
      ),
    [prefersDarkMode]
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ErrorBoundary
        onError={(error: Error, stackTrace: string) => {
          /* Log the error to an error reporting service */
          console.error("----ErrorBoundary---");
          console.error(error);
          console.debug(stackTrace);
        }}
      >
        <Provider store={store}>
          <ReactReduxFirebaseProvider {...rrfProps}>
            <ReduxFirestoreProvider {...rrfProps}>
              {/* <Context.FirestoreContext.Provider value={DB()}> */}
              <BrowserRouter>
                <SnackbarProvider
                  maxSnack={3}
                  anchorOrigin={{ horizontal: "right", vertical: "top" }}
                >
                  <MainLayout />
                </SnackbarProvider>
              </BrowserRouter>
              {/* </Context.FirestoreContext.Provider> */}
            </ReduxFirestoreProvider>
          </ReactReduxFirebaseProvider>
        </Provider>
      </ErrorBoundary>
    </ThemeProvider>
  );
}

export default App;
