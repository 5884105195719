import {
  Card,
  CardContent,
  CardHeader,
  Chip,
  Typography,
} from "@material-ui/core";
import { COMPONENTS } from "../../../../../constants/colors";
import React from "react";
import { useNavigate } from "react-router-dom";

type GroupCardProps = {
  title: string;
  subtitle: string;
  children: React.ReactNode;
  groupId: string;
  link?: string;
};

const GroupCard = ({
  title,
  subtitle,
  groupId,
  children,
  link,
}: GroupCardProps) => {
  const navigate = useNavigate();
  return (
    <Card
      style={{
        height: "100%",
        backgroundColor: COMPONENTS.DASHBOARD.CARD.BACKGROUND,
        borderRadius: 5,
        borderStyle: "solid",
        borderWidth: 2,
        borderColor: `#AAA`,
        cursor: "pointer",
      }}
      onClick={() => (link ? navigate(link) : null)}
    >
      <CardHeader
        title={
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              style={{
                color: "#FFF",
                fontSize: 16,
                fontFamily: "Roboto",
                fontWeight: `500`,
              }}
            >
              {`${title}`}
            </Typography>
            <Chip
              size="small"
              label={
                <Typography
                  style={{
                    color: "#FFF",
                    fontSize: 10,
                    fontFamily: "Roboto",
                    fontWeight: `300`,
                  }}
                >
                  {`${groupId}`}
                </Typography>
              }
            />
          </div>
        }
        subheader={
          <Typography
            style={{
              color: `#AAA`,
              fontSize: 14,
              fontFamily: "Roboto",
              fontWeight: `300`,
            }}
          >
            {`${subtitle}`}
          </Typography>
        }
      />
      <CardContent>{children}</CardContent>
    </Card>
  );
};

export default GroupCard;
