import React from 'react';

const LoadingCircle = (props: {width: any, height: any, border: any}) => {
  const circleStyle = {
    width: props.width,
    height: props.height,
    border: '' + props.border + ' solid rgba(0, 0, 0, 0.2)',
    borderTop: '' + props.border + ' solid #3498db',
    borderRadius: '50%',
    animation: 'spin 1s linear infinite',
  };

  return (
    <div style={circleStyle} />
  );
};

// CSS-in-JS for animation
const styleSheet = document.styleSheets[0];
styleSheet.insertRule(
  `
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  `,
  styleSheet.cssRules.length
);

export default LoadingCircle;
