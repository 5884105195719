import { Button, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import useCollectionGroup from "../../../../../firestore/hooks/useCollectionGroup";
import BookedRow from "./BookedRow";
import BookingModal from "./BookingModal";

interface IOverviewProps {
  campaign: Record<string, any>;
}

const Booked = ({ campaign }: IOverviewProps) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const booked = useCollectionGroup<Record<string, any>>(
    "booking",
    [["campaignId", "==", campaign.id]],
    [[`startDate`, "asc"]],
    300
  );

  useEffect(() => {
    console.log(`Found ${Object.entries(booked ?? {}).length}booking entries`);
  }, [booked]);

  return (
    <div className="w-full flex flex-col">
      <Typography
        style={{
          fontSize: 24,
          fontWeight: "lighter",
          margin: 10,
          marginBottom: 14,
          marginTop: 14,
        }}
      >
        Gebucht
      </Typography>
      <Button onClick={() => setModalOpen(true)} variant="outlined">
        Werbeplatz buchen
      </Button>
      <div className="flex flex-row flex-wrap justify-start content-center items-center mt-8">
        {Object.entries(booked || {}).map(([k, v]) => {
          return (
            <BookedRow
              id={v.id}
              name={v.adspaceName}
              endDate={v.endDate}
              startDate={v.startDate}
              adspaceId={v.adspaceId}
              campaign={campaign}
              fileUrl={v.fileUrl}
              filePath={v.filePath}
              key={k}
            />
          );
        })}
      </div>
      <BookingModal
        open={modalOpen}
        campaign={campaign}
        close={() => setModalOpen(false)}
      />
    </div>
  );
};

export default Booked;
