import React from "react";
import { Route, Routes } from "react-router-dom";
import withRole from "../../hoc/withRole";
import { UserRole } from "../../firestore/models/User";
import Dashboard from "./Dashboard";
import Campaign from "./Campaign";
import AdspaceForm from "./Dashboard/components/AdspaceForm";
import CampaignForm from "./Dashboard/components/CampaignForm";
import CampaignEdit from "./Dashboard/components/CampaignEdit";
import AdspaceEdit from "./Dashboard/components/AdspaceEdit";
import DefaultEdit from "./Dashboard/components/DefaultEdit";
import Overview from "./Dashboard/components/Overview";

const MarketingModule = () => {
  return (
    <>
      <Routes>
        <Route path={`overview`} element={<Overview />} />
        <Route path={`edit/default`} element={<DefaultEdit />} />
        <Route path={`edit/campaign/:id`} element={<CampaignEdit />} />
        <Route path={`edit/adspace/:id`} element={<AdspaceEdit />} />
        <Route path={`create/campaign`} element={<CampaignForm />} />
        <Route path={`create/adspace`} element={<AdspaceForm />} />
        <Route path={`campaign/:id`} element={<Campaign />} />
        <Route path="/" element={<Dashboard />} />
      </Routes>
    </>
  );
};

export default withRole(UserRole.Marketing)(MarketingModule);
