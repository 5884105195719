import moment from "moment";
import { ACTION_TYPE } from ".";

type LicenseStats = {
  active: boolean; //License has been active
  create: boolean;
  upgrade: boolean; //License has been upgraded from test to live
  extended: boolean; //License has been extended
  expired_prod: boolean; //Prod-License has expired
  expired_test: boolean; //Test-License has expired
};

export const calculateTimeline = (
  actions: Array<Record<string, any>>,
  monthFirst: Date,
  monthLast: Date,
  licenseCreated: Date
): LicenseStats => {
  let active: boolean = false;
  let create: boolean = false;
  let upgrade: boolean = false;
  let extended: boolean = false;
  let expired_prod: boolean = false;
  let expired_test: boolean = false;

  let validAfterCurrentMonth: boolean = true;

  actions.map((action) => {
    //#################################################
    //######### HANDLE FREE LICENSES ##################
    //#################################################
    if (action.license_type === `free`) {
      if (moment(licenseCreated).isBefore(moment(monthFirst))) {
        //Lizenz schon vor diesem Monat erstellt und dauerhaft gültig
        active = true;
      } else if (moment(licenseCreated).isBefore(moment(monthLast))) {
        //Lizenz wurde in diesem Monat erstellt und ist dauerhaft gültig
        active = true;
        create = true;
      } else {
        //Lizenz wird erst zu einem späteren Zeitpunkt erstellt
      }
      //#################################################
      //######### HANDLE PROD LICENSES ##################
      //#################################################
    } else {
      const actionExecutedAt = new Date(action.action_executed_at);
      const bookingValidUnitl = new Date(action.license_valid_until);
      
    }
    // } else if (
    //   moment(new Date(action.license_valid_until)).isBefore(moment(monthFirst))
    // ) {
    //   //Buchung ist in diesem Monat nicht mehr aktiv
    // } else if (
    //   moment(new Date(action.license_valid_until)).isBefore(moment(monthLast))
    // ) {
    //   //Buchung läuft in diesem Monat aus
    //   licenseCanceledAtMonth = true;
    // } else if (
    //   moment(new Date(action.license_valid_until)).isAfter(moment(monthLast))
    // ) {
    //   if (
    //     moment(new Date(action.action_executed_at)).isAfter(
    //       moment(monthFirst)
    //     ) &&
    //     moment(new Date(action.action_executed_at)).isBefore(moment(monthLast))
    //   ) {
    //     // licenseActiveAtMonth = true
    //     licenseCreatedAtMonth = true;
    //     //Buchung wurde diesen Monat erstellt
    //   }
    //   if (
    //     moment(new Date(action.action_executed_at)).isBefore(moment(monthFirst))
    //   ) {
    //     licenseActiveAtMonth = true;
    //   }
    //   //Buchung läuft über diesen Monat hinaus
    // }
  });

  return {
    active: active,
    create: create,
    upgrade: upgrade,
    extended: extended,
    expired_prod: expired_prod,
    expired_test: expired_test,
  };
};
